
import React, { useState } from "react";
import PropTypes from 'prop-types';
import db from "./firestore";
import { collection, query, where, orderBy, startAfter, getDocs, limit } from "firebase/firestore/lite";
import thirtyDaysAgoMillis from "../components/datehelper";
import flag from "country-code-emoji";
import dateToText from "../components/datetext";
import countryName from "../components/countryName";
import Loading from "./loading";
import { jobLink } from "./links";
import { TagsLight } from "./tag";
import { Link } from "gatsby";
import cms, { queryConstraintsWithWebsite } from "../components/cms/cms";

const Remoteness = ({ remote }) => {
    const text = (remote === "remote") ? "Remote" : "Partially Remote";
    if (remote === "on_site") return <></>;
    else if (remote === "remote") {
      return <Link
        className="px-2 py-1 inline-flex leading-5 font-semibold rounded-full bg-green-100 text-green-800 hover:bg-green-50"
        to="/locations/remote/">
        {text}
      </Link>;
    } else {
      return <span className="px-2 py-1 inline-flex leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        {text}
      </span>;
    }
  }
  
  const Entries = ({ entries }) =>
  <ul>
    {
      entries.map(entry =>
        <li key={entry.id}>
          <div
            className="block border-b border-gray-100 last:border-b-0">
            <div className="px-4 pb-4 sm:px-6">
              <div className="flex-shrink-0 flex -ml-2 py-4">
                <Remoteness remote={entry.remote} />
              </div>
              <div className="sm:mb-4">
                <a
                  href={jobLink(entry.company, entry.title, entry.id)} eventCategory="Job Title" eventAction="Click" eventLabel={entry.id}
                  className="truncate text-lg leading-5 font-medium text-gray-900 hover:text-gray-500">
                  <span>{entry.title}</span>
                </a>
              </div>
              <div className="mt-2 sm:flex sm:justify-between">
                <div>
                  <div className="sm:flex">
                    <div className="flex flex-shrink-0 items-center font-bold leading-5 text-gray-500">
                      {entry.image ? <img className="h-5 w-5 mr-2" src={entry.image} /> : <></>}
                      <span>{entry.company}</span>
                    </div>
                    {(entry.salaryFrom && entry.salaryTo) ?
                      <div className="sm:ml-4 mt-2 flex items-center leading-5 text-gray-500 sm:mt-0 sm:border-l sm:pl-4">
                        {entry.currency + entry.salaryFrom} - {entry.currency + entry.salaryTo}
                      </div> : <></>
                    }
                    <div className="mt-2 flex items-center leading-5 text-gray-500 sm:mt-0 sm:border-l sm:ml-4 sm:pl-4">
                      <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                      </svg>
                      <span>{entry.location}</span>
                    </div>
                    <div className="sm:mx-4 mt-2 flex items-center leading-5 text-gray-500 sm:mt-0 sm:border-l sm:pl-4">
                      <svg className="sm:hidden flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path></svg>
                      <span>{countryName(entry.country)}</span>
                      <span className="text-xl ml-3">{flag(entry.country)}</span>
                    </div>
                  </div>
                  <TagsLight jobTags={entry.tags} />
                </div>
                <div className="mt-2 flex flex-shrink-0 items-center leading-5 text-gray-500 sm:mt-0">
                  <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                  </svg>
                  <span>
                    <time dateTime={new Date(entry.date)}>{dateToText(new Date(entry.date))}</time>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </li>
      )
    }
  </ul>

const MoreJobs = ({ country, remote, tag }) => {

    const [entries, setEntries] = useState(null);
    const [moreEntries, setMoreEntries] = useState(false);
    const [lastDoc, setLastDoc] = useState(null);


    const loadMore = async () => {
        const jobs = await composeQuery(remote, country, tag, lastDoc);
        setEntries(entries.concat(jobs));
    }

    const composeQuery = async (remote, country, tag, after) => {
        const queryConstraints = Array(where("date", "<", thirtyDaysAgoMillis()), orderBy("date", "desc"));
    
        if (remote) queryConstraints.push(where("remote", "==", remote));
        if (country) queryConstraints.push(where("country", "in", country));
        if (tag) queryConstraints.push(where("tags", "array-contains", tag));
        if (after) queryConstraints.push(startAfter(after));
        queryConstraints.push(limit(10));
    
        const constraints = queryConstraintsWithWebsite(queryConstraints, tag)
    
        const snapshot = await getDocs(query(collection(db, "featured_jobs"), ...constraints));
    
        const lastDoc = snapshot.docs[snapshot.docs.length - 1];
        snapshot.docs.length < 10 ? setMoreEntries(false) : setMoreEntries(true);
        setLastDoc(lastDoc);
    
        return snapshot.docs.map(doc => {
          return { ...{ id: doc.id }, ...doc.data() };
        });
      }
    
      const mainQuery = (remote, country, tag) => composeQuery(remote, country, tag, null);
    
      React.useEffect(() => {
        mainQuery(remote, country, tag)
          .then(jobs => setEntries(jobs));
      }, [remote, country, tag])

    return <>
        {
            (entries && entries.length > 0) ?
                <h2 className="my-5 text-center sm:py-2 text-3xl tracking-tight leading-10 font-extrabold text-gray-700">
                    More {cms.keyword} Jobs
                </h2> : <></>
        }
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
            {entries ? <Entries entries={entries} /> : <Loading size={10} />}
        </div>
        {moreEntries ?
            <div className="mt-5 flex justify-center">
                <div onClick={() => loadMore()}
                    className="cursor-pointer sm:w-auto w-full flex items-center justify-center px-4 py-2 text-base leading-6 font-semibold rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-red-700 transition ease-in-out duration-150">
                    <span>Load more</span>
                </div>
            </div> : <></>
        }
    </>
}

Entries.propTypes = {
    entries: PropTypes.array
  };

export default MoreJobs;